import * as React from 'react';
import { graphql } from 'gatsby';
import { Grid, Container } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ContentfulFlashRentaFija } from '../../types/graphql-types';
import FlashLayout from '../components/FlashLayout';
import IconTitle from '../components/IconTitle';
import { useFlashRentaFijaPost } from '../operations/queries';
import getCategoryColor from '../utils/getCategoryColor';
import { Flash as FlashProps } from '../models/Flash';
import options from '../utils/getRichTextOptions';
import Index from '../assets/icons/icon-Index.svg';
import Airtable from '../components/Airtable';
import { Table as TableProps } from '../models/Table';
import StickyBox from 'react-sticky-box';
import PDFPreview from '../components/PdfPreview';
import LightboxCarousel from '../components/LightboxCarousel';
import { renderRichText } from "gatsby-source-contentful/rich-text"
import useIsClient from '../utils/isClient';
import { HeadApi } from '../components/HeadApi';

interface Props {
  data: {
    contentfulFlashRentaFija: ContentfulFlashRentaFija;
  };
}

export const Head = ({data}) => {
  const post: FlashProps = useFlashRentaFijaPost(data.contentfulFlashRentaFija);
  // console.log("[POST HEAD]", post);
  return(
    <HeadApi
      title={post.title}
      description={post.description}
      img={post.fluid?.gatsbyImageData.images.fallback.src}
    />
  );
};

export default function FlashTecnico({ data }: Props): JSX.Element {
  const color = getCategoryColor('rentaFija');

  const post: FlashProps = useFlashRentaFijaPost(data.contentfulFlashRentaFija);

  // const indices = 'https://airtable.com/embed/shrcrcg9KsdFUYma4';

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isClient = useIsClient();

  if(isClient){

    return (
      <FlashLayout post={post} color={color}>
        <>
          <>
            <h2>{post.description}</h2>
          </>
          <Grid container>
            <Grid
              // style={{ paddingRight: '10px', paddingLeft: '10px' }}
              item
              xs={12}
              sm={12}
              md={post?.tables ? 4 : 3}
            >
              {' '}
              <StickyBox offsetTop={100} style={{ width: '100%' }}>
                <Container
                  style={
                    mobile
                      ? { paddingLeft: 0, paddingRight: 0 }
                      : { paddingLeft: 0, paddingRight: 20 }
                  }
                >
                  {post?.tables &&
                    post?.tables.map((tabla: TableProps) => {
                      if (tabla.tipo == 'tarjeta') {
                        //tarjeta
                        return (
                          <IconTitle icon={Index} title={tabla.titulo}>
                            <Airtable
                              src={tabla.tabla}
                              description={tabla.descripcion}
                              card={true}
                            />
                          </IconTitle>
                        );
                      }
                    })}
                </Container>
              </StickyBox>{' '}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{ background: 'white', marginTop: 20 }}
            >
              {post.text && renderRichText(post.text, options)}
  
              {post.images && <LightboxCarousel images={post.images} />}
  
              {post.pdf && <PDFPreview pdf={post.pdf} />}
              
              {post.tables &&
                post.tables.map((tabla: TableProps) => {
                  if (tabla.tipo == '50%') {
                    return (
                      <>
                        <IconTitle icon={Index} title={tabla.titulo}>
                          <Airtable
                            src={tabla.tabla}
                            description={tabla.descripcion}
                            card={false}
                          />
                        </IconTitle>
                      </>
                    );
                  }
                })}
            </Grid>
          </Grid>
          <>
            {post.tables &&
              post.tables.map((tabla: TableProps) => {
                if (tabla.tipo == '100%') {
                  return (
                    <>
                      <Grid item xs={12} sm={12} md={6}></Grid>
                      <IconTitle icon={Index} title={tabla.titulo}>
                        <Airtable
                          src={tabla.tabla}
                          description={tabla.descripcion}
                          card={false}
                        />
                      </IconTitle>
                    </>
                  );
                }
              })}
          </>
        </>
      </FlashLayout>
    );
  }else{
    return(<></>)
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulFlashRentaFija(slug: { eq: $slug }) {
      ...flashRentaFija
    }
  }
`;
